import type { ReactElement } from 'react';

import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import type { HighchartSeries } from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import type * as Highcharts from 'highcharts';
import { useFinalColorScheme } from 'useFinalColorScheme';
import { createCorrelation, createCorrelationMap } from '../../../market/asset/correlation/LatestCorrelationService';
import { gradientColorsLightMode } from 'theme/colors';
import { gradientColorsDarkMode } from 'theme/colors';
import type { ApolloError } from '@apollo/client';

interface Element {
  id: string;
  name: string;
}
export interface LatestCorrelationData {
  firstElement: Element;
  secondElement: Element;
  value: number;
}

interface LatestCorrelationContainerProps {
  data?: LatestCorrelationData[];
  loading: boolean;
  error: ApolloError | undefined;
}

export const calculateChartData = ({
  data,
}: {
  data: LatestCorrelationData[];
}): HighchartSeries[] => {
  const elements = Object.fromEntries(
    data.flatMap((row) => [row.firstElement, row.secondElement]).map((element) => [element.id, element])
  );
  const sortedElements = Object.values(elements).sort((a, b) => (a.name ?? a.id).localeCompare(b.name ?? b.id));
  const sortedElementIds = sortedElements.map((element) => element.id);

  const correlationMap = createCorrelationMap(data);
  const correlation = createCorrelation(correlationMap, sortedElementIds);

  return [
    {
      data: correlation.flatMap((_, xCatIndex) => {
        return correlation.map((_, yCatIndex) => {
          const value = correlation[yCatIndex][xCatIndex];

          return {
            x: xCatIndex,
            value: value ?? 0,
            y: yCatIndex,
            textValue: value?.toFixed(2) ?? '',
          };
        });
      }),
      type: 'heatmap',
      dataLabels: {
        enabled: true,
        format: '{point.textValue}',
      },
    },
  ];
};

export const calculateOptions = ({
  data,
  colorScheme,
}: {
  data: LatestCorrelationData[];
  colorScheme: 'light' | 'dark';
}): Highcharts.Options => {
  const elements = Object.fromEntries(
    data.flatMap((row) => [row.firstElement, row.secondElement]).map((element) => [element.id, element])
  );
  const sortedElements = Object.values(elements).sort((a, b) => (a.name ?? a.id).localeCompare(b.name ?? b.id));
  const x = sortedElements.map((element) => element.name ?? element.id);
  const y = [...x].reverse();

  return {
    colorAxis: {
      min: -1,
      max: 1,
      stops: colorScheme === 'light' ? gradientColorsLightMode : gradientColorsDarkMode,
    },
    xAxis: {
      categories: x,
      opposite: true,
      lineWidth: 0,
    },
    yAxis: {
      categories: y,
      title: {
        text: null,
      },
    },
    tooltip: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };
};

const CorrelationChart = ({ data, loading, error }: LatestCorrelationContainerProps): ReactElement => {
  const colorScheme = useFinalColorScheme();

  return (
    <HighChartsWrapper<LatestCorrelationData[]>
      data={data}
      calculateChartData={(data) => calculateChartData({ data })}
      calculateOptions={(data) => calculateOptions({ data, colorScheme })}
      loading={loading}
      error={error}
    />
  );
};

export default CorrelationChart;
