import type { ReactElement } from 'react';
import GLink from './GLink/GLink';
import { Typography, Stack } from '@mui/joy';

export const TitleWithLink = ({ title, link, icon }: { title: string; link?: string; icon?: ReactElement }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      width={'100%'}
      justifyContent={'flex-start'}
      minHeight={'30px'}
      alignItems={'center'}
    >
      {icon}
      <Typography level="title-lg">{link ? <GLink to={link}>{title}</GLink> : title}</Typography>
    </Stack>
  );
};
