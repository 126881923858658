import { bignumber } from 'mathjs';

import type { LatestCorrelationData } from 'components/technical/charts/CorrelationChart/CorrelationChart';

export const createCorrelationMap = (twoElementMetrics: LatestCorrelationData[]): Map<string, Map<string, number>> => {
  const elementToCorrelation = new Map<string, Map<string, number>>();
  for (const corr of twoElementMetrics) {
    let primaryElementMapping = elementToCorrelation.get(corr.firstElement.id);
    if (!primaryElementMapping) {
      primaryElementMapping = new Map<string, number>();
      elementToCorrelation.set(corr.firstElement.id, primaryElementMapping);
    }

    primaryElementMapping.set(corr.secondElement.id, bignumber(corr.value).toNumber());

    let secondaryElementMapping = elementToCorrelation.get(corr.secondElement.id);
    if (!secondaryElementMapping) {
      secondaryElementMapping = new Map<string, number>();
      elementToCorrelation.set(corr.secondElement.id, secondaryElementMapping);
    }

    secondaryElementMapping.set(corr.firstElement.id, bignumber(corr.value).toNumber());
  }

  return elementToCorrelation;
};
export const createCorrelation = (
  correlationMap: Map<string, Map<string, number>>,
  x: string[]
): (number | null)[][] => {
  const correlation: (number | null)[][] = [];
  for (let j = x.length - 1; j >= 0; j--) {
    const jElement = x[j];
    const elementCorrelation: (number | null)[] = [];
    for (let i = 0; i < x.length; i++) {
      const iElement = x[i];
      if (iElement === jElement) {
        elementCorrelation.push(1);
      } else if (i > j) {
        // y axis is reversed

        const value = correlationMap.get(iElement)?.get(jElement);
        elementCorrelation.push(value ?? null);
      } else {
        elementCorrelation.push(null);
      }
    }
    correlation.push(elementCorrelation);
  }

  return correlation;
};
