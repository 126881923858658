import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import LatestCorrelationContainer from './LatestCorrelationContainer';
import ChartWithTitle from '../../../ChartWithTitle.tsx';
import type { PublicAsset } from '../Asset.types';

const LatestCorrelationSection = ({
  metric,
  supportedAssets,
  defaultAssets,
}: {
  metric: Label; // Metric is required
  supportedAssets: PublicAsset[]; // Generic array of assets
  defaultAssets: PublicAsset[]; // Default selection of assets
}): ReactElement => {
  return (
    <ChartWithTitle title="Latest correlation" paper>
      <Stack spacing={1.5}>
        <LatestCorrelationContainer metric={metric} supportedAssets={supportedAssets} defaultAssets={defaultAssets} />
      </Stack>
    </ChartWithTitle>
  );
};

export default LatestCorrelationSection;
