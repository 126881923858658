import type { FunctionComponent } from 'react';
import { UserSettings } from 'components/management/UserSettings.types';
import { type AssetLabelInput, isAssetLabelInput } from 'components/market/asset/AssetLabelService';
import { type IMultifactorScores, useAssetRiskMetricsSuspenseQuery, useAssetsSuspenseQuery } from 'generated/graphql';
import {
  assetMetricColumn,
  nameColumn,
  symbolColumn,
  clusterColumn,
  priceChangesGroupColumns,
  type AssetMetricValues,
} from '../../../technical/grids/SharedReportColumns';

import { metricColumnsConfiguration } from './AssetScreenerService';
import { useReportAssetGroup } from 'components/UseReportAssetGroups';
import { usePriceChanges } from 'components/portfolio/openPositions/UsePriceChanges';
import type { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import GAgGridPresets from 'components/technical/grids/GAgGridPresets';
import { assetScreenerDefaultPresets } from './assetScreenerDefaultPresets';
import { formatNumber } from 'components/formatter.utils';

type AssetRowType = { asset: AssetLabelInput };

type AssetScreenerTableProps = {
  scores: Array<IMultifactorScores> | undefined;
};

const AssetScreenerTable: FunctionComponent<AssetScreenerTableProps> = ({ scores }) => {
  const availableAssetsQueryResult = useAssetsSuspenseQuery();
  const reportAssetGroup = useReportAssetGroup();

  const assetIds = availableAssetsQueryResult.data?.assets.feature.map((asset) => asset.id) ?? [];

  const pricesChangesResult = usePriceChanges(assetIds);

  const assetRiskMetricsQueryResult = useAssetRiskMetricsSuspenseQuery({
    variables: {
      assetIds,
    },
  });

  const metricsByAsset = new Map<string, AssetMetricValues>(
    assetRiskMetricsQueryResult.data?.assets.details.map((assetMetrics) => [
      assetMetrics.asset,
      assetMetrics.metrics,
    ]) ?? []
  );

  if (!reportAssetGroup.loaded) {
    return <reportAssetGroup.Fallback />;
  }
  if (!pricesChangesResult.loaded) {
    return <pricesChangesResult.Fallback />;
  }

  return (
    <GAgGridPresets<AssetRowType>
      presetSettingsKey={UserSettings.AssetScreenerPresets}
      defaultPresets={assetScreenerDefaultPresets}
      rowData={availableAssetsQueryResult.data.assets.feature
        .filter((asset) => isAssetLabelInput(asset))
        .map((asset) => ({ asset }))}
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
      enableCharts
      enableRangeSelection
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: true,
      }}
      columnDefs={[
        {
          headerName: 'Asset Details',
          colId: 'asset-details',
          marryChildren: true,
          children: [
            nameColumn(),
            symbolColumn({ initialHide: false }),
            ...reportAssetGroup.clusters.map((cluster) =>
              clusterColumn<AssetRowType>(cluster, reportAssetGroup.assetAndGroupClusterMapToGroup)
            ),
          ],
        },
        {
          headerName: 'Multifactors',
          colId: 'multifactors',
          marryChildren: true,
          children: scores?.length
            ? [
                ...scores.map((score) => {
                  const scoresMap = new Map(score.assetScores.map((score) => [score.asset.id, score.score]));
                  return {
                    headerName: score.multifactor.multifactor.name,
                    colId: `multifacor-${score.multifactor.multifactor.id}`,
                    type: ['numericColumn'],
                    valueGetter: (params: ValueGetterParams): number | undefined => {
                      if (!params.data) {
                        return undefined;
                      }

                      const score = scoresMap.get(params.data?.asset.id);
                      if (score) {
                        return Number.parseFloat(score);
                      }
                    },
                    valueFormatter: (params: ValueFormatterParams) => formatNumber(params.value),
                  };
                }),
              ]
            : [],
        },
        ...priceChangesGroupColumns<AssetRowType>(
          pricesChangesResult.priceSummary,
          pricesChangesResult.priceChanges24hByAssetId
        ),
        ...Object.entries(metricColumnsConfiguration).map(([groupHeader, groupColumns]) => ({
          headerName: groupHeader,
          marryChildren: true,
          children: groupColumns.map(
            ({ metricLabel, initialHide }): ColDef<{ asset: { id: string } }> =>
              assetMetricColumn(metricsByAsset, metricLabel, initialHide)
          ),
        })),
      ]}
    />
  );
};

export default AssetScreenerTable;
