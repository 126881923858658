import { Stack } from '@mui/joy';
import { uniqBy } from 'lodash/fp';
import { type FunctionComponent, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { type Asset, getAssets } from 'components/market/asset/Asset.types.ts';
import { createAssetSelectOptions } from 'components/market/asset/AssetService.tsx';
import {
  createAccountIdAutocompleteOptions,
  createSubAccountIdAutocompleteOptions,
} from 'components/portfolio/account/AccountService.tsx';
import { useDrawer } from 'components/technical/Drawer/UseDrawer.tsx';
import FormStaticMultiAutocomplete from 'components/technical/form/FormStaticMultiAutocomplete.tsx';
import GFormProvider from 'components/technical/form/GFormProvider.tsx';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling.tsx';

import { type ISubAccountAssetFiltersQuery, useSubAccountAssetFiltersQuery } from 'generated/graphql.tsx';
import {
  type Field,
  useShowSubAccountAssetFilter,
  useSubAccountAssetFilterFieldsForPath,
} from './UseShowSubAccountAssetFilters.tsx';
import {
  useSyncSubAccountAssetFilterWithCurrentData,
  useSubAccountAssetFilters,
  type SubAccountAssetFilters,
} from './UseSubAccountAssetFilters.tsx';
import { createSubfundIdAutocompleteOptions } from '../../portfolio/fund/SubFundService.tsx';

type SubAccountAssetFilterForm = Omit<SubAccountAssetFilters, 'assetIds'> & { assets: Asset[] | undefined };

const SubAccountAssetFilterDrawerContainer: FunctionComponent<{ open: boolean }> = ({ open }) => {
  const { drawer, closeDrawer } = useDrawer();
  const shouldShowSubAccountAssetFilter = useShowSubAccountAssetFilter();

  useEffect(() => {
    if (drawer && !shouldShowSubAccountAssetFilter) {
      closeDrawer();
    }
  }, [drawer, closeDrawer, shouldShowSubAccountAssetFilter]);

  const filterQueryResult = useDefaultErrorHandling(useSubAccountAssetFiltersQuery());

  if (!filterQueryResult.loaded) {
    return <filterQueryResult.Fallback />;
  }

  return <SubAccountAssetFilterDrawer open={open} queryData={filterQueryResult.data} />;
};

type FilterDrawerProps = {
  open: boolean;
  queryData: ISubAccountAssetFiltersQuery;
};

const SubAccountAssetFilterDrawer: FunctionComponent<FilterDrawerProps> = ({ open, queryData }) => {
  const { subAccountAssetFilters, setSubAccountAssetFilters } = useSubAccountAssetFilters();

  const [assets, assetsById] = useMemo(() => {
    const positionAssets = queryData.portfolio.positions.positions.map((p) => p.asset);
    const assets = getAssets(uniqBy((a) => a.id, positionAssets));
    return [assets, new Map(assets.map((a) => [a.id, a]))];
  }, [queryData]);

  const methods = useForm<SubAccountAssetFilterForm>({
    defaultValues: {
      subFundIds: subAccountAssetFilters.subFundIds,
      subAccountIds: subAccountAssetFilters.subAccountIds,
      assets: subAccountAssetFilters.assetIds?.map((id) => assetsById.get(id)),
    },
  });

  const fields = useSubAccountAssetFilterFieldsForPath();
  const shouldShowField = (fieldName: Field): boolean => fields?.includes(fieldName) ?? true;

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (open) {
      // reset from dirty values and set applied filters values on drawer reopen
      methods.setValue('subFundIds', subAccountAssetFilters.subFundIds);
      methods.setValue('subAccountIds', subAccountAssetFilters.subAccountIds);
      methods.setValue('accountIds', subAccountAssetFilters.accountIds);
      methods.setValue(
        'assets',
        subAccountAssetFilters.assetIds?.map((id) => assetsById.get(id)!)
      );
    }
    // rest only makes sense on open drawer event
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useSyncSubAccountAssetFilterWithCurrentData(queryData);

  const subFunds = queryData.portfolio.subFunds.list;
  const assetOptions = createAssetSelectOptions(assets);

  const onSubmit = (formData: SubAccountAssetFilterForm): void => {
    setSubAccountAssetFilters({
      subFundIds: formData.subFundIds,
      subAccountIds: formData.subAccountIds,
      accountIds: formData.accountIds,
      assetIds: formData.assets?.map((asset) => asset.id),
    });
  };

  return (
    <GFormProvider {...methods}>
      <Stack spacing={1.5}>
        {shouldShowField('subFundIds') && (
          <FormStaticMultiAutocomplete
            name="subFundIds"
            {...createSubfundIdAutocompleteOptions(subFunds)}
            label="Sub-fund"
            width="fullWidth"
          />
        )}
        {shouldShowField('accountIds') && (
          <FormStaticMultiAutocomplete
            name="accountIds"
            {...createAccountIdAutocompleteOptions(queryData.portfolio.accounts)}
            label="Account"
            width="fullWidth"
          />
        )}
        {shouldShowField('subAccountIds') && (
          <FormStaticMultiAutocomplete
            name="subAccountIds"
            {...createSubAccountIdAutocompleteOptions(queryData.portfolio.accounts)}
            label="Sub-account"
            width="fullWidth"
          />
        )}
        {shouldShowField('assets') && (
          <FormStaticMultiAutocomplete {...assetOptions} width="fullWidth" label="Asset*" name="assets" limitTags={3} />
        )}
        <GButton
          variant="plain"
          onClick={(): void => {
            methods.reset({
              subFundIds: [],
              subAccountIds: [],
              assets: [],
            });
          }}
        >
          Clear all
        </GButton>
        <GButton onClick={methods.handleSubmit(onSubmit)}>Apply filter</GButton>
      </Stack>
    </GFormProvider>
  );
};

export default SubAccountAssetFilterDrawerContainer;
