import { memo, type ReactElement, useState } from 'react';
import type { BacktestingConfiguration } from '../BacktestConfiguration.types.ts';
import { Stack } from '@mui/joy';
import { IWindowType, usePortfolioCorrelationsQuery } from '../../../../../generated/graphql.tsx';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import { useDebounce } from '../../../../UseDebounce.ts';
import { isNil } from 'lodash/fp';
import { HistoricalPortfolioCorrelations } from './HistoricalPortfolioCorrelations.tsx';
import GInput from 'components/technical/inputs/GInput/GInput.tsx';
import { Select } from '../../../../technical/inputs';
import type { SelectOption } from '../../../../technical/inputs/Select/Select.props.ts';
import { formatEnum } from 'components/formatter.utils.ts';
import type { LatestCorrelationData } from 'components/technical/charts/CorrelationChart/CorrelationChart.tsx';
import { LatestPortfolioCorrelations } from './LatestPortfolioCorrelations.tsx';

interface PortfolioCorrelationSectionProps {
  backtestingConfig: BacktestingConfiguration;
}

const BACKEND_MIN_WINDOW = 3;

const isWindowValid = (windowType: IWindowType, window: string | null): boolean => {
  if (windowType === IWindowType.Expanding) {
    return true;
  }

  if (window === null) {
    return false;
  }

  const value = Number.parseFloat(window);
  if (!Number.isSafeInteger(value)) {
    return false;
  }

  return value >= BACKEND_MIN_WINDOW;
};

const windowTypeOptions: SelectOption<IWindowType>[] = Object.entries(IWindowType).map(([key, value]) => ({
  value: value as IWindowType,
  key: key,
  label: formatEnum(value),
}));

const PortfolioCorrelations = ({ backtestingConfig }: PortfolioCorrelationSectionProps): ReactElement => {
  const [windowType, setWindowType] = useState(IWindowType.Expanding);
  const [window, setWindow] = useState<string | null>('30');
  const debouncedWindow = useDebounce(window, 300);

  const queryOutput = usePortfolioCorrelationsQuery({
    variables: {
      input: {
        portfolios: backtestingConfig.portfolios.map((p) => p.id) ?? [],
        dateRange: backtestingConfig.range,
        windowParams: {
          windowType: windowType,
          windowSize: windowType !== IWindowType.Expanding ? Number.parseInt(debouncedWindow ?? '') : undefined,
        },
      },
    },
    skip: !isWindowValid(windowType, debouncedWindow),
  });

  const queryCorrelationData = queryOutput.data?.portfolio.portfolioCorrelations ?? [];

  const latestCorrelations: LatestCorrelationData[] = queryCorrelationData.flatMap((pc) => {
    const lastValue = pc.correlations.at(-1)?.value;
    if (isNil(lastValue)) {
      return [];
    }
    return [
      {
        value: lastValue,
        firstElement: pc.portfolioPair.portfolioA,
        secondElement: pc.portfolioPair.portfolioB,
      },
    ];
  });

  const isExpanding = windowType === IWindowType.Expanding;

  return (
    <Stack rowGap={defaultRowSpacing}>
      <Stack direction={'row'} columnGap={1} alignSelf={'flex-end'}>
        {!isExpanding && (
          <GInput
            error={isWindowValid(windowType, window) ? '' : 'Invalid value'}
            showLabelAboveInput
            label="Window"
            value={window ?? ''}
            type={'number'}
            onChange={(value): void => setWindow(value)}
            width="normal"
          />
        )}
        <Select
          showLabelAboveInput
          disableClearable
          label="Type"
          value={windowType}
          onChange={(value): void => setWindowType(value)}
          options={windowTypeOptions}
          width="normal"
        />
      </Stack>
      <LatestPortfolioCorrelations data={latestCorrelations} loading={queryOutput.loading} error={queryOutput.error} />
      <HistoricalPortfolioCorrelations warmupEnabled={isExpanding} {...queryOutput} />
    </Stack>
  );
};

export default memo(PortfolioCorrelations);
