import type { ApolloError } from '@apollo/client';
import { Stack } from '@mui/joy';
import ChartWithTitle from 'components/ChartWithTitle';
import CorrelationChart, {
  type LatestCorrelationData,
} from 'components/technical/charts/CorrelationChart/CorrelationChart';

export const LatestPortfolioCorrelations = ({
  data,
  loading,
  error,
}: {
  data: LatestCorrelationData[];
  loading: boolean;
  error: ApolloError | undefined;
}) => {
  return (
    <ChartWithTitle title="Latest correlation" paper>
      <Stack spacing={1.5}>
        <CorrelationChart data={data} loading={loading} error={error} />
      </Stack>
    </ChartWithTitle>
  );
};
