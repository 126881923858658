import { Divider, Grid, Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import ColorSchemeChanger from './ColorSchemeToggler';
import TilesDateRangeSelector from './TilesDateRangeSelector';
import { JournalModeChanger } from './JournalModeChanger.tsx';
import SectionPanel from 'components/technical/layout/SectionPanel.tsx';
import type { GButtonProps } from 'components/technical/inputs/GButton/GButton.props.ts';
import { TitleWithLink } from 'components/technical/TitleWithLink.tsx';
import { SubHeader } from 'components/technical/typography/SubHeader.tsx';
import {
  AccountBalanceWalletOutlined,
  DiamondOutlined,
  GroupsOutlined,
  NotificationsActiveOutlined,
  ReceiptLongOutlined,
  SavingsOutlined,
  SettingsOutlined,
  ViewDayOutlined,
} from '@mui/icons-material';

const PanelContent = ({
  description,
  action,
}: { description: string; action?: (props: Required<Pick<GButtonProps, 'width' | 'color'>>) => ReactElement }) => {
  return (
    <Stack alignItems={'start'} spacing={1.5} height="fullContent">
      <Divider />
      <Stack direction="row" spacing={1} minHeight={'30px'}>
        <Typography level="body-sm">{description}</Typography>
      </Stack>
      {action?.({ width: 'minContent', color: 'primary' })}
    </Stack>
  );
};

const SECTION_PANEL_STYLE = {
  minHeight: '150px',
  height: '100%',
};

const dashboardGroups = [
  {
    key: 'portfolio',
    title: (
      <Stack width="100%" spacing="10">
        <Stack direction="row" spacing="10" alignSelf="end">
          <Stack direction="row" spacing={5}>
            <Stack direction="column" alignSelf="end" spacing={0.5}>
              <Typography level="title-sm" alignSelf="end">
                Default tiles time frame
              </Typography>
              <TilesDateRangeSelector />
            </Stack>
            <Stack direction="column" alignSelf="end" spacing={0.5}>
              <Typography level="title-sm" alignSelf="end">
                Mode
              </Typography>
              <ColorSchemeChanger color="primary" variant="button-group" />
            </Stack>
          </Stack>
        </Stack>
        <SubHeader title="Portfolio" withBorder />
      </Stack>
    ),
    items: [
      <SectionPanel
        key="accounts"
        sx={SECTION_PANEL_STYLE}
        title={
          <TitleWithLink
            icon={<AccountBalanceWalletOutlined fontSize="large" />}
            title="Accounts"
            link="/app/management/account"
          />
        }
      >
        <PanelContent description="View and edit your accounts." />
      </SectionPanel>,
      <SectionPanel
        key="sub-funds"
        sx={{
          height: '100%',
        }}
        title={
          <TitleWithLink
            icon={<SavingsOutlined fontSize="large" />}
            title="Sub-funds"
            link="/app/management/sub-funds"
          />
        }
      >
        <PanelContent description="Create sub-funds by grouping sub-accounts/exchange accounts/wallets" />
      </SectionPanel>,
      <SectionPanel
        key="positions"
        sx={SECTION_PANEL_STYLE}
        title={
          <TitleWithLink
            icon={<ViewDayOutlined fontSize="large" />}
            title="Positions"
            link="/app/management/positions"
          />
        }
      >
        <PanelContent description="Edit portfolio positions for a specific date" />
      </SectionPanel>,
      <SectionPanel
        key="journal"
        title={<TitleWithLink icon={<ReceiptLongOutlined fontSize="large" />} title="Journal" />}
      >
        <PanelContent
          description="Set cost basis by organization or sub-fund"
          action={(): ReactElement => <JournalModeChanger width="fullWidth" />}
        />
      </SectionPanel>,
    ],
  },
  {
    key: 'market',
    title: <SubHeader title="Market" withBorder />,
    items: [
      <SectionPanel
        key="assets"
        sx={SECTION_PANEL_STYLE}
        title={
          <TitleWithLink icon={<DiamondOutlined fontSize="large" />} title="Assets" link="/app/management/asset" />
        }
      >
        <PanelContent description="View and edit your assets." />
      </SectionPanel>,
      <SectionPanel
        key="asset-groups"
        sx={SECTION_PANEL_STYLE}
        title={
          <TitleWithLink
            icon={<GroupsOutlined fontSize="large" />}
            title="Groups"
            link="/app/management/asset-groups"
          />
        }
      >
        <PanelContent description="View and edit your asset groups." />
      </SectionPanel>,
    ],
  },
  {
    key: 'settings',
    title: <SubHeader title="Settings" withBorder />,
    items: [
      <SectionPanel
        key="notifications"
        sx={SECTION_PANEL_STYLE}
        title={
          <TitleWithLink
            title="Notifications"
            icon={<NotificationsActiveOutlined fontSize="large" />}
            link="/app/management/notification"
          />
        }
      >
        <PanelContent description="Subscribe and unsubscribe from different types of notifications" />
      </SectionPanel>,

      <SectionPanel
        key="account-settings"
        sx={SECTION_PANEL_STYLE}
        title={
          <TitleWithLink
            title="Account settings"
            icon={<SettingsOutlined fontSize="large" />}
            link="/app/management/settings"
          />
        }
      >
        <PanelContent description="Configure credentials, multi-factor authentication, and privacy" />
      </SectionPanel>,
    ],
  },
];

const ManagementDashboard = (): ReactElement => {
  return (
    <Stack direction="column" spacing={10}>
      <Stack direction="column" p={1}>
        {dashboardGroups.map((group) => (
          <Stack key={group.key} direction="column" spacing={2}>
            {group.title}
            <Grid
              paddingBottom={4}
              container
              spacing={4} // Space between grid items
              columns={{ xs: 1, sm: 4 }} // Ensure 4 columns per row
            >
              {group.items.map((item, index) => (
                <Grid key={index} xs={1} height="100%">
                  {/* Takes 1/4 of the row */}
                  {item}
                </Grid>
              ))}
            </Grid>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default ManagementDashboard;
