import Card from '@mui/joy/Card';
import type { ReactElement, ReactNode } from 'react';
import type { SxProps } from '@mui/system';
import { Typography } from '@mui/joy';

const SectionPanel = ({
  title,
  children,
  sx,
}: { title: string | ReactNode; children: ReactNode; sx?: SxProps }): ReactElement => (
  <Card sx={sx}>
    {typeof title === 'string' ? <Typography level={'h4'}>{title}</Typography> : title}
    {children}
  </Card>
);

export default SectionPanel;
