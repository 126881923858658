import { useCallback, useContext } from 'react';

import type { DrawerType } from './Drawer.types.ts';
import { Context } from './DrawerContext.tsx';
import type { drawerContent } from './Drawer.constants.tsx';

export interface UseDrawer {
  openDrawer<Drawer extends DrawerType>(
    drawer: Drawer,
    openKey?: string,
    extraProps?: Omit<(typeof drawerContent)[Drawer]['children'], 'open'>
  ): void;
  drawer: DrawerType | null;
  closeDrawer: () => void;
  openKey: string;
}

export const useDrawer = (): UseDrawer => {
  const { setDrawer, drawer, openKey } = useContext(Context);

  return {
    openDrawer: useCallback(
      (drawer: DrawerType, openKey: string, extraProps): void => {
        setDrawer(drawer, openKey, extraProps);
      },
      [setDrawer]
    ),
    closeDrawer: useCallback((): void => {
      setDrawer(null);
    }, [setDrawer]),
    drawer,
    openKey,
  };
};
