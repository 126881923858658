import HeaderBar from '../HeaderBar/HeaderBar';

export const SubHeader = ({ title, withBorder = false }: { title: string; withBorder?: boolean }) => {
  return (
    <HeaderBar
      title={title}
      sx={{ pb: 2, width: '100%', borderBottom: withBorder ? '1px solid var(--joy-palette-neutral-200)' : 'none' }}
    />
  );
};
