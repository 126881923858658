import { AutocompleteListbox, AutocompleteOption, Box } from '@mui/joy';
import React, { type ReactElement, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatNumber } from 'components/formatter.utils';
import { truncateText } from '../../text.styles.ts';

const InputSuggestion = (props: {
  autoFill?: boolean;
  children: React.ReactElement<{
    onFocus: () => void;
    onBlur: () => void;
    name: string;
    onChange: () => void;
  }>;
  suggestion?: string | null;
}): ReactElement => {
  const { setValue, clearErrors, watch } = useFormContext();
  const [suggestionVisible, setSuggestionVisible] = useState(false);

  // Ref to track the last applied suggestion
  const lastAppliedSuggestion = useRef<string | null>(null);

  // Track whether the user has manually interacted with the input
  const userInputRef = useRef(false);

  // Watch the current value of the input
  const inputValue = watch(props.children.props.name);

  useEffect(() => {
    // Reset auto-fill state when suggestions change
    if (props.suggestion && props.suggestion !== lastAppliedSuggestion.current) {
      lastAppliedSuggestion.current = null;
    }
  }, [props.suggestion]);

  useEffect(() => {
    // Auto-fill only if the user hasn't interacted manually
    if (
      props.autoFill &&
      props.suggestion &&
      !userInputRef.current &&
      props.suggestion !== lastAppliedSuggestion.current
    ) {
      selectSuggestion(props.suggestion);
      lastAppliedSuggestion.current = props.suggestion; // Mark as applied
      setSuggestionVisible(false);
    }
  }, [props.suggestion, props.autoFill]);

  useEffect(() => {
    // Detect manual user input changes
    if (inputValue !== lastAppliedSuggestion.current) {
      userInputRef.current = true; // Mark that the user has manually interacted
    }
  }, [inputValue]);

  if (!props.suggestion || props.suggestion === '') {
    return props.children;
  }

  const cloned = React.cloneElement(props.children, {
    onFocus: () => {
      props.children.props.onFocus?.();
      setSuggestionVisible(true);
    },
    onBlur: () => {
      props.children.props.onBlur?.();
      setSuggestionVisible(false);
    },
    ...(props.children.props.onChange && {
      onChange: () => {
        props.children.props.onChange?.(); // Call the original onChange if present
        userInputRef.current = true; // Mark manual interaction on input change
      },
    }),
  });

  function selectSuggestion(suggestion: string): void {
    setValue(props.children.props.name, suggestion); // Update the input value
    clearErrors(props.children.props.name);
    lastAppliedSuggestion.current = suggestion; // Track the applied suggestion
    userInputRef.current = false; // Reset manual interaction flag
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {cloned}
      {suggestionVisible && (
        <AutocompleteListbox
          sx={{
            position: 'absolute',
            width: '100%',
          }}
        >
          {props.suggestion && (
            <AutocompleteOption
              key={props.suggestion}
              onMouseDown={(): void => selectSuggestion(props.suggestion ?? '')}
              onTouchStart={(): void => selectSuggestion(props.suggestion ?? '')}
            >
              <Box
                sx={{
                  maxWidth: '100%',
                  ...truncateText,
                }}
              >
                {formatNumber(props.suggestion)}
              </Box>
            </AutocompleteOption>
          )}
        </AutocompleteListbox>
      )}
    </Box>
  );
};

export default InputSuggestion;
