import type Highcharts from 'highcharts';
import { Stack } from '@mui/joy';
import { WarmUpInput } from '../../../risk/WarmUpInput.tsx';
import type { HighchartSeries } from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import ChartWithTitle from 'components/ChartWithTitle';
import type { IPortfolioCorrelationsQuery } from 'generated/graphql';
import { useState } from 'react';
import { formatNumber } from 'components/formatter.utils.ts';
import { parseUtcDate } from 'components/date.utils.ts';
import {
  dateTimeAxisFormat,
  noYAxisTitle,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils.ts';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import type { ApolloError } from '@apollo/client';

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...tooltipFormat,
    ...noYAxisTitle,
    plotOptions: {
      series: {
        marker: {
          symbol: 'circle',
        },
      },
    },
  };
};

const calculateChartData = (data: IPortfolioCorrelationsQuery, warmupPeriod: number): HighchartSeries[] => {
  return data.portfolio.portfolioCorrelations.map((corr) => ({
    name: [corr.portfolioPair.portfolioA.name, corr.portfolioPair.portfolioB.name].sort().join(' / '),
    data: corr.correlations.slice(warmupPeriod).map((corr) => ({
      x: parseUtcDate(corr.date).valueOf(),
      y: corr.value,
      textValue: formatNumber(corr.value),
    })),
    type: 'line',
  }));
};

export const HistoricalPortfolioCorrelations = ({
  data,
  loading,
  error,
  warmupEnabled,
}: {
  data?: IPortfolioCorrelationsQuery | undefined;
  loading?: boolean | undefined;
  error?: ApolloError | undefined;
  warmupEnabled: boolean;
}) => {
  const [warmupPeriod, setWarmupPeriod] = useState<string>('10');
  const parsedWarmup = Number.parseFloat(warmupPeriod);

  return (
    <ChartWithTitle title="Historical correlation" paper>
      <Stack direction={'row'} columnGap={1}>
        {warmupEnabled && (
          <WarmUpInput disabled={!warmupEnabled} value={warmupPeriod} onChange={(val) => setWarmupPeriod(val)} />
        )}
      </Stack>
      <HighChartsContainer<IPortfolioCorrelationsQuery>
        data={data}
        loading={loading ?? false}
        error={error}
        calculateOptions={(): Highcharts.Options => calculateOptions()}
        calculateChartData={(data): HighchartSeries[] =>
          calculateChartData(data, warmupEnabled && Number.isSafeInteger(parsedWarmup) ? parsedWarmup : 0)
        }
      />
    </ChartWithTitle>
  );
};
