import { useMemo } from 'react';
import { type TFallback, useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { TupleKeyMap } from 'components/TupleKeyMap.ts';
import { usePricesForPositionsQuery } from 'generated/graphql';

export enum PriceSummaryType {
  firstDayOfYear = 'FIRST_DAY_OF_YEAR',
  firstDayOfQuarter = 'FIRST_DAY_OF_QUARTER',
  firstDayOfMonth = 'FIRST_DAY_OF_MONTH',
  latestToday = 'LATEST_TODAY',
}

export type UsePriceChangesLoaded = {
  loaded: true;
  Fallback: undefined;
  priceChanges24hByAssetId: Map<string, number | undefined>;
  priceSummary: TupleKeyMap<[string, PriceSummaryType], string>;
};

export type UsePriceChangesNotLoaded = {
  loaded: false;
  Fallback: TFallback;
  priceChanges24hByAssetId: undefined;
  priceSummary: undefined;
};

export type UsePriceChanges = UsePriceChangesNotLoaded | UsePriceChangesLoaded;

export function usePriceChanges(assetIds: string[]): UsePriceChanges {
  const pricesQueryResult = useDefaultErrorHandling(
    usePricesForPositionsQuery({
      variables: {
        assetIds,
      },
      skip: assetIds.length === 0,
    })
  );

  return useMemo(() => {
    if (!pricesQueryResult.loaded) {
      return {
        loaded: false,
        Fallback: pricesQueryResult.Fallback,
        priceSummary: undefined,
        priceChanges24hByAssetId: undefined,
      };
    }

    const priceSummary = new TupleKeyMap<[string, PriceSummaryType], string>();
    for (const assetSummary of pricesQueryResult.data.assets.priceSummary) {
      const asset = assetSummary.asset.id;
      if (assetSummary.today) {
        priceSummary.set([asset, PriceSummaryType.latestToday], assetSummary.today);
      }
      if (assetSummary.firstDayOfMonth) {
        priceSummary.set([asset, PriceSummaryType.firstDayOfMonth], assetSummary.firstDayOfMonth);
      }
      if (assetSummary.firstDayOfQuarter) {
        priceSummary.set([asset, PriceSummaryType.firstDayOfQuarter], assetSummary.firstDayOfQuarter);
      }
      if (assetSummary.firstDayOfYear) {
        priceSummary.set([asset, PriceSummaryType.firstDayOfYear], assetSummary.firstDayOfYear);
      }
    }

    const priceChanges24hByAssetId = new Map(
      pricesQueryResult.data.assets.changes.map((change) => [
        change.assetId,
        change.priceChange ? Number.parseFloat(change.priceChange) : undefined,
      ])
    );

    return { priceSummary, priceChanges24hByAssetId, loaded: true, Fallback: undefined };
  }, [pricesQueryResult]);
}
