import { Card, Typography } from '@mui/joy';
import sortBy from 'lodash/fp/sortBy';
import { bignumber, type BigNumber } from 'mathjs';
import type { ReactElement } from 'react';

import type { SunburstChartData } from 'components/technical/charts/SunburstChart/SunburstChart.props';
import SunburstChart from 'components/technical/charts/SunburstChart/SunburstChart.tsx';
import type { AssetGroupChartProps } from './AssetGroupChart.props';
import { formatCash } from '../../../../formatter.utils';
import { calculateGroupChartInput } from '../../Group.utils';
import type { AssetListGroup } from '../AssetDashboard.types';
import { getMarketCap } from '../AssetDashboard.utils';
import bigNumMath from '../../../../../bigNumMath.ts';
import { isNil } from 'lodash/fp';

const calculateAssetGroupChartData = (group: AssetListGroup): SunburstChartData => {
  const assetMarketCap = sortBy(
    (element) => getMarketCap(element).toNumber(),
    group.subgroups.flatMap((subGroup) => subGroup.elements)
  );

  const positionIndex = Math.floor(0.75 * assetMarketCap.length);
  const marketCap: BigNumber | undefined =
    assetMarketCap.length > positionIndex ? getMarketCap(assetMarketCap[positionIndex]) : undefined;

  const params = calculateGroupChartInput({
    root: group,
    valueProvider: (val) => (isNil(val) ? bignumber(0) : getMarketCap(val)),
    labelProvider: (asset) => asset.asset.name ?? asset.asset.symbol,
    textProvider: (value) => formatCash(value),
    cap: marketCap,
    rootValue: bigNumMath.sum(assetMarketCap.map((el) => getMarketCap(el))),
  });

  return {
    ...params,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    hoverinfo: 'label+text',
    textinfo: 'label',
  };
};

const AssetGroupChart = ({ group }: AssetGroupChartProps): ReactElement => {
  const chartData = calculateAssetGroupChartData(group);

  return (
    <Card>
      <div>
        <Typography color="neutral" level="body-md">
          Market cap
        </Typography>
      </div>
      <SunburstChart data={chartData} />
    </Card>
  );
};

export default AssetGroupChart;
