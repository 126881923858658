import { Stack } from '@mui/joy';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { UserSettings } from 'components/management/UserSettings.types';
import GAgGridPresets from 'components/technical/grids/GAgGridPresets';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import {
  IAssetType,
  type IOpenPositionsSpotQuery,
  useAssetRiskMetricsQuery,
  useOpenPositionsSpotQuery,
} from 'generated/graphql';

import { positionsSpotsDefaultPresets } from './defaultPresets';
import {
  accountColumn,
  amountColumn,
  assetMetricColumn,
  type AssetMetricValues,
  balanceColumn,
  balanceToMetricColumn,
  clusterColumn,
  costBasisColumn,
  exposureColumn,
  type MetricColumnConfig,
  nameColumn,
  priceChangesGroupColumns,
  sideColumn,
  slotColumn,
  subAccountColumn,
  subFundGroupColumn,
  symbolColumn,
  unrealizedPnlColumn,
  venueColumn,
  weightColumn,
} from '../../technical/grids/SharedReportColumns';
import { usePriceChanges } from './UsePriceChanges';
import { useReportAssetGroup } from '../../UseReportAssetGroups';
import { groupCostBasisByAssetAndSubFund } from '../account/SubAccountPositionsService';
import { isNil, uniq } from 'lodash/fp';
import type { ColDef } from 'ag-grid-community';
import { mapSubAccountAndDimensionToSubFund } from 'components/bookkeeping/report/Report.utils';
import { MAX_DRAWDOWN, MAX_DRAWDOWN_1Y } from 'components/metrics/NonPortfolioMetricsData';
import { calculateSubAccountToSubFundsMapper } from './PositionsSummary.util.ts';

const GROUP_COLUMN_MIN_WIDTH = 220;

const metricColumnsConfiguration: Record<string, MetricColumnConfig[]> = {
  Fundamentals: [
    { metricLabel: 'met:market_cap', initialHide: false },
    { metricLabel: 'met:total_val_locked', initialHide: false },
    { metricLabel: 'met:volume-24h', initialHide: false },
    { metricLabel: 'met:liquidity', initialHide: false },
    { metricLabel: MAX_DRAWDOWN, initialHide: false },
    { metricLabel: MAX_DRAWDOWN_1Y, initialHide: false },
  ],

  '30 Days': [
    {
      metricLabel: 'met:rolling_volatility-30',
      initialHide: false,
    },
    { metricLabel: 'met:rolling_beta_btc-30', initialHide: false },
    { metricLabel: 'met:rolling_beta_eth-30', initialHide: false },
    { metricLabel: 'met:rolling_sharpe-30', initialHide: false },
    { metricLabel: 'met:rolling_sortino-30', initialHide: false },
    { metricLabel: 'met:rolling_var95-30', initialHide: false },
    { metricLabel: 'met:rolling_var99-30', initialHide: false },
    { metricLabel: 'met:rolling_skewness-30', initialHide: true },
    { metricLabel: 'met:rolling_kurtosis-30', initialHide: true },
  ],

  '60 Days': [
    { metricLabel: 'met:rolling_volatility-60', initialHide: true },
    { metricLabel: 'met:rolling_beta_btc-60', initialHide: true },
    { metricLabel: 'met:rolling_beta_eth-60', initialHide: true },
    { metricLabel: 'met:rolling_sharpe-60', initialHide: true },
    { metricLabel: 'met:rolling_sortino-60', initialHide: true },
    { metricLabel: 'met:rolling_var95-60', initialHide: true },
    { metricLabel: 'met:rolling_var99-60', initialHide: true },
    { metricLabel: 'met:rolling_skewness-60', initialHide: true },
    { metricLabel: 'met:rolling_kurtosis-60', initialHide: true },
  ],
  '90 Days': [
    { metricLabel: 'met:rolling_volatility-90', initialHide: true },
    { metricLabel: 'met:rolling_beta_btc-90', initialHide: true },
    { metricLabel: 'met:rolling_beta_eth-90', initialHide: true },
    { metricLabel: 'met:rolling_sharpe-90', initialHide: true },
    { metricLabel: 'met:rolling_sortino-90', initialHide: true },
    { metricLabel: 'met:rolling_var95-90', initialHide: true },
    { metricLabel: 'met:rolling_var99-90', initialHide: true },
    { metricLabel: 'met:rolling_skewness-90', initialHide: true },
    { metricLabel: 'met:rolling_kurtosis-90', initialHide: true },
  ],
};

type RowData = IOpenPositionsSpotQuery['portfolio']['positions']['positions'][number];
const PositionsSpot: FunctionComponent = () => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const positionsSpotQueryResult = useDefaultErrorHandling(
    useOpenPositionsSpotQuery({
      variables: { subAccountAssetFilters },
    })
  );
  const reportAssetGroup = useReportAssetGroup();

  const assetIds =
    positionsSpotQueryResult.data?.portfolio.positions.positions.map((position) => position.asset.id) ?? [];

  const pricesChangesResult = usePriceChanges(assetIds);

  const assetRiskMetricsQueryResult = useDefaultErrorHandling(
    useAssetRiskMetricsQuery({
      // should prevent request being before portfolio assets loaded
      skip: !positionsSpotQueryResult.loaded,
      variables: {
        assetIds: uniq(
          positionsSpotQueryResult.data?.portfolio.positions.positions
            .filter(
              (position) => position.asset.type !== IAssetType.Derivative && position.asset.type !== IAssetType.Exchange
            )
            .map((position) => position.asset.id) ?? []
        ),
      },
    })
  );

  if (!positionsSpotQueryResult.loaded) {
    return <positionsSpotQueryResult.Fallback />;
  }
  if (!reportAssetGroup.loaded) {
    return <reportAssetGroup.Fallback />;
  }
  if (!pricesChangesResult.loaded) {
    return <pricesChangesResult.Fallback />;
  }
  if (!assetRiskMetricsQueryResult.loaded) {
    return <assetRiskMetricsQueryResult.Fallback />;
  }

  const portfolio = positionsSpotQueryResult.data.portfolio;
  const totalBalance = bignumber(portfolio.positions.summary.balance.total);
  const reportData = portfolio.positions.positions.filter((position) => !isNil(position.spot));
  const costBasisPerAssetAndSubFund = groupCostBasisByAssetAndSubFund(portfolio.journal.costBasis);

  const metricsByAsset = new Map<string, AssetMetricValues>(
    assetRiskMetricsQueryResult.data?.assets.details.map((assetMetrics) => [
      assetMetrics.asset,
      assetMetrics.metrics,
    ]) ?? []
  );

  const subFunds = portfolio.subFunds.list;
  const costBasisDimension = portfolio.journal.preferences.dimension ?? null;
  const subAccountToCostBasisSubFundsMapper = calculateSubAccountToSubFundsMapper(
    costBasisDimension,
    portfolio.subFunds.list
  );
  const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);

  return (
    <Stack spacing={1.5} height="100%">
      <GAgGridPresets<RowData>
        presetSettingsKey={UserSettings.PositionsSpotPresets}
        defaultPresets={positionsSpotsDefaultPresets}
        rowData={reportData}
        groupDefaultExpanded={-1}
        sideBar={{
          toolPanels: ['columns', 'filters'],
        }}
        enableCharts
        enableRangeSelection
        autoGroupColumnDef={{
          minWidth: GROUP_COLUMN_MIN_WIDTH,
        }}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
        }}
        columnDefs={[
          {
            headerName: 'Asset Details',
            colId: 'asset-details',
            marryChildren: true,
            children: [
              nameColumn(),
              symbolColumn({
                initialRowGroup: true,
                initialRowGroupIndex: 1,
              }),
              ...reportAssetGroup.clusters.map((cluster) =>
                clusterColumn<RowData>(cluster, reportAssetGroup.assetAndGroupClusterMapToGroup)
              ),
            ],
          },
          {
            headerName: 'Account Details',
            colId: 'account-details',
            marryChildren: true,
            children: [accountColumn(), subAccountColumn(), venueColumn()],
          },
          {
            headerName: 'Sub-funds',
            colId: 'sub-funds',
            marryChildren: true,
            children: subFundDimensions.map((subFundDimension) =>
              subFundGroupColumn(subFundDimension, subAccountAndDimensionToSubFund)
            ),
          },
          sideColumn({ initialHide: true }),
          slotColumn(),
          {
            headerName: 'Current positions',
            colId: 'currentPositions',
            marryChildren: true,
            children: [
              exposureColumn({ sideAware: true, initialHide: true }),
              exposureColumn({ sideAware: false, initialHide: true }),
              amountColumn({ sideAware: false, initialHide: true }),
              amountColumn({ sideAware: true, initialHide: false }),
              balanceColumn(),
              balanceToMetricColumn({
                metricsByAsset,
                metricLabel: 'met:volume-24h',
                headerName: 'Balance / Volume',
              }),
              balanceToMetricColumn({
                metricsByAsset,
                metricLabel: 'met:liquidity',
                headerName: 'Balance / Liquidity',
              }),
              unrealizedPnlColumn(costBasisPerAssetAndSubFund, subAccountToCostBasisSubFundsMapper),
              costBasisColumn(costBasisPerAssetAndSubFund, subAccountToCostBasisSubFundsMapper),
              weightColumn(totalBalance),
            ],
          },
          ...priceChangesGroupColumns<RowData>(
            pricesChangesResult.priceSummary,
            pricesChangesResult.priceChanges24hByAssetId
          ),

          ...Object.entries(metricColumnsConfiguration).map(([groupHeader, groupColumns]) => ({
            headerName: groupHeader,
            marryChildren: true,
            children: groupColumns.map(
              ({ metricLabel, initialHide }): ColDef<{ asset: { id: string } }> =>
                assetMetricColumn(metricsByAsset, metricLabel, initialHide)
            ),
          })),
        ]}
      />
    </Stack>
  );
};

export default PositionsSpot;
