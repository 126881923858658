import { bignumber } from 'mathjs';
import { groupBy, isNil } from 'lodash/fp';
import type { DailyReturnsValue, RowData } from './DailyPnl.types.ts';
import bigNumMath from '../../../../bigNumMath.ts';
import type { IAggFuncParams, ValueGetterParams } from 'ag-grid-community';
import { parseUtcDate } from 'components/date.utils.ts';

export const valueGetters = (params: ValueGetterParams<RowData>): null | DailyReturnsValue => {
  if (!params.node?.group) {
    // no need to handle group levels - calculated in the 'aggFunc'
    const data = params.data;
    if (!data) {
      return null;
    }

    const parsedEndingBalance = bignumber(data.return.balance);
    const parsedPnl = bignumber(data.return.return);
    const parsedDate = parseUtcDate(data.date);

    return {
      days: [
        {
          pnl: parsedPnl,
          balance: parsedEndingBalance,
          date: parsedDate,
        },
      ],
      value: data.return.weighted_return,
    };
  }

  return null;
};

export const returnsAgg = (params: IAggFuncParams<RowData>): null | DailyReturnsValue => {
  const values = params.values;
  if (isNil(values)) {
    return null;
  }

  const nonNullValues: DailyReturnsValue['days'] = values.flatMap((val) => val.days).filter((val) => !isNil(val));
  if (nonNullValues.length === 0) {
    return null;
  }

  const perDayValues = groupBy((val) => val.date.unix(), nonNullValues);
  const perDayHp = Object.values(perDayValues).map((values) => {
    const dayPnl = bigNumMath.sum(values.map((val) => val.pnl));
    const dayBalance = bigNumMath.sum(values.map((val) => val.balance));
    const denum = dayBalance.sub(dayPnl);
    return denum.eq(0) ? bignumber(0) : dayPnl.div(denum);
  });

  const twr = perDayHp.reduce((acc, hp) => hp.add(1).mul(acc), bignumber(1)).sub(1);

  return {
    days: nonNullValues,
    value: twr.toNumber(),
  };
};
